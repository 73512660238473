import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { LinearProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { reactIcons } from "./../utils/icons";
import { getReq } from "../utils/ApiHandler";
import { Footer } from "./Footer";
import Loader from "./Loader";
import moment from "moment";
const Schedule = () => {
  const [isLoading, setIsLoading] = useState(false);
  // const data = [
  //   {
  //     id: 1,
  //     plant_name: "Plant A",
  //     location: "Madhya Pradesh",
  //     mobile_number: "8754821456",
  //     plf: "8%",
  //     apc: "10%",
  //   },
  //   {
  //     id: 2,
  //     plant_name: "Plant B",
  //     location: "Gujarat",
  //     mobile_number: "8754821456",
  //     plf: "50%",
  //     apc: "70%",
  //   },
  // ];
  const getData = async () => {
    setIsLoading(true);
    getReq("/v1/data").then((res) => {
      if (res.status) {
        setData(res.data.data);
        setIsLoading(false);
      } else {
        console.log("error");
        setIsLoading(false);
      }
    });
  };
  useEffect(() => {
    getData();
  }, []);
  const [data, setData] = useState([]);
  const [rows, setRows] = useState([...data]);
  const navigate = useNavigate();
  function getFullName(params) {
    return `${params.row.first_name || ""} ${params.row.last_name || ""}`;
  }
  const handleFilter = (itemId) => {
    return setRows(rows.filter((item) => item.id !== itemId));
  };
  const columns = [
    {
      field: "plant_name",
      headerName: "Plant Name",
      headerClassName: "super-app-theme--header",
      type: "string",
      flex: 2,
      // colSpan: ({ row }) => {
      //   console.log(row);
      //   if (row.id === 2) {
      //     return 6;
      //   }
      //   return undefined;
      // },
    },
    {
      field: "location",
      headerName: "Location",
      headerClassName: "super-app-theme--header",
      type: "string",
      flex: 2,
    },
    // {
    //   field: "fullName",
    //   headerName: "Full Name",
    // headerClassName: 'super-app-theme--header',
    //   flex: 3,

    //   valueGetter: getFullName,
    // },
    {
      field: "mobile_number",
      headerName: "Mobile Number",
      headerClassName: "super-app-theme--header",
      type: "string",
      flex: 2,
    },

    {
      field: "plf",
      headerName: "PLF",
      headerClassName: "super-app-theme--header",
      type: "string",
      flex: 2,
    },
    {
      field: "apc",
      headerName: "APC",
      headerClassName: "super-app-theme--header",
      type: "string",
      flex: 2,
    },
    {
      field: "action",
      headerName: "Actions",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div
            onClick={() => navigate(`/plant-details/${params.row.id}`)}
            className="text-xl cursor-pointer m-1"
          >
            {reactIcons.openArrow}
          </div>
        );
      },
      flex: 2,
    },
  ];
  return (
    <>
      <Loader isLoading={isLoading} />
      <div className="w-full  data-grid">
        <div className=" h-[600px] w-full card p-3">
          <div className=" px-3">
            <div className="space-y-1">
              <h6 className="font-bold mb-2 text-[16px]">Plants</h6>
              <table class="mt-3 table table-bordered text-center">
                <thead>
                  <tr>
                    <th className="text-secondary" scope="col">
                      Sr. No.
                    </th>
                    <th className="text-secondary" scope="col">
                      Created At
                    </th>
                    <th className="text-secondary" scope="col">
                      Name
                    </th>
                    <th className="text-secondary" scope="col">
                      Location
                    </th>
                    <th className="text-secondary" scope="col">
                      Mobile Number
                    </th>

                    <th className="text-secondary" scope="col">
                      PLF
                    </th>
                    <th className="text-secondary" scope="col">
                      APC
                    </th>
                    <th className="text-secondary" scope="col">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data?.length ? (
                    data.map((item, index) => {
                      return (
                        <tr key={item.id}>
                          <th scope="row">{index + 1}</th>

                          <td>
                            {moment(item.created_at).format(
                              "MMM Do YYYY, (h:mm A)"
                            )}
                          </td>
                          <td>{item.plantname}</td>
                          <td>
                            {item.location == "Gujarat" ? "Mundra" : "Tiroda"}
                          </td>
                          <td>{item.phonenumber || "Under Development"}</td>
                          {/* <td>
                            {item.timestamp
                              ? new Date(item.timestamp * 1000).toLocaleString()
                              : "--"}
                          </td> */}
                          <td>{(Math.random() * 100).toFixed(2)}</td>
                          <td>{(Math.random() * 100).toFixed(2)}</td>
                          <td
                            onClick={() =>
                              navigate(`/plant-details/${item.id}`)
                            }
                            className="text-xl cursor-pointer m-1 "
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {reactIcons.eye}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <span className="flex text-center">No data found</span>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          {/* <DataGrid
          rows={rows}
          getEstimatedRowHeight={() => 52}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 8,
              },
            },
          }}
          pageSizeOptions={[8, 10, 15]}
          slots={{
            toolbar: GridToolbar,
            loadingOverlay: LinearProgress,
          }}
          hideFooter
          hideFooterPagination
          disableColumnMenu
        /> */}
        </div>
      </div>
      <div className="mt-80 ">
        <Footer />
      </div>
    </>
  );
};

export default Schedule;
