import axios from "axios";
// import { BASE_URL } from "./env";

const responseFormatter = (status, data, error) => {
  return { status, data, error };
};
const BASE_URL = "https://powersectorapi.vnvserver.com/api";
export const postApiReq = async (url, data) => {
  const route_url = BASE_URL + url;

  const token = sessionStorage.getItem("user-data");

  return await axios
    .post(route_url, data, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return responseFormatter(true, response.data, null);
    })
    .catch((e) => {
      if (e?.response?.status === 403) {
        sessionStorage.removeItem("user-data");
        window.location.href = "/";
      } else if (e) {
        return responseFormatter(false, null, e?.response?.data || null);
      } else {
        return responseFormatter(false, null, e?.response?.data || null);
      }
    });
};

export const getApiReq = async (url) => {
  const token = sessionStorage.getItem("user-data");
  const route_url = ENV.BASE_URL + url;
  return await axios
    .get(route_url, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return responseFormatter(true, response.data, null);
    })
    .catch((e) => {
      if (e.response.status === 403) {
        sessionStorage.removeItem("user-data");
        window.location.href = "/";
      } else if (e) {
        return responseFormatter(false, null, e?.response?.data || null);
      } else {
        return responseFormatter(false, null, e?.response?.data || null);
      }
    });
};

export const postReq = async (url, data) => {
  const route_url = BASE_URL + url;
  return await axios
    .post(route_url, data, {
      headers: {
        Accept: "application/json",
      },
    })
    .then((response) => {
      return responseFormatter(true, response.data, null);
    })
    .catch((e) => {
      if (e) {
        return responseFormatter(false, null, e?.response?.data || null);
      } else {
        return responseFormatter(false, null, e?.response?.data || null);
      }
    });
};

export const getReq = async (url) => {
  const route_url = BASE_URL + url;
  return await axios
    .get(route_url, {
      headers: {
        Accept: "application/json",
      },
    })
    .then((response) => {
      return responseFormatter(true, response.data, null);
    })
    .catch((e) => {
      if (e) {
        return responseFormatter(false, null, e?.response?.data || null);
      } else {
        return responseFormatter(false, null, e?.response?.data || null);
      }
    });
};
