import React, { useEffect, useState } from "react";
import {
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Scatter,
} from "recharts";
import { getReq } from "../utils/ApiHandler";
// import { newDataPr } from "../utils/constants";

const data = [
  {
    name: "Plant A",
    umo: 590,
    value: 800,
    total: 1400,
    cnt: 490,
  },
  {
    name: "Plant B",
    umo: 868,
    value: 967,
    total: 1506,
    cnt: 590,
  },
  {
    name: "Plant C",
    umo: 1397,
    value: 1098,
    total: 989,
    cnt: 350,
  },
  {
    name: "Plant D",
    umo: 1480,
    value: 1200,
    amt: 1228,
    cnt: 480,
  },
  {
    name: "Plant E",
    umo: 1520,
    value: 1108,
    total: 1100,
    cnt: 460,
  },
  {
    name: "Plant F",
    umo: 1400,
    value: 680,
    total: 1700,
    cnt: 380,
  },
];

let data1 = [
  {
    id: 1,
    plantname: "PlantA",
    phonenumber: "234234234",
    photo: null,
    location: "Gujarat",
    created_at: "2024-01-03T00:00:00.000Z",
    updated_at: "2024-01-03T00:00:00.000Z",
    plantdata: [
      {
        id: 1,
        plantId: 1,
        type: "Coalstock",
        parameter: "Atype",
        umo: "MT",
        value: "20000",
        total: "65334",
        created_at: "2024-01-03T00:00:00.000Z",
        update_at: "2024-01-17T17:12:35.000Z",
      },
      {
        id: 7,
        plantId: 1,
        type: "Coalstock",
        parameter: "BType",
        umo: "MT",
        value: "13988",
        total: "65334",
        created_at: "2024-01-03T00:00:00.000Z",
        update_at: "2024-01-26T17:12:40.000Z",
      },
      {
        id: 8,
        plantId: 1,
        type: "Coalreceipt",
        parameter: "BType",
        umo: "MT",
        value: "13988",
        total: "64334",
        created_at: "2024-01-03T00:00:00.000Z",
        update_at: "2024-01-26T17:12:40.000Z",
      },
      {
        id: 9,
        plantId: 1,
        type: "Coalreceipt",
        parameter: "BType",
        umo: "MT",
        value: "13988",
        total: "64334",
        created_at: "2024-01-03T00:00:00.000Z",
        update_at: "2024-01-26T17:12:40.000Z",
      },
      {
        id: 10,
        plantId: 1,
        type: "Coalconsumption",
        parameter: "BType",
        umo: "MT",
        value: "13988",
        total: "15334",
        created_at: "2024-01-03T00:00:00.000Z",
        update_at: "2024-01-26T17:12:40.000Z",
      },
    ],
  },
  {
    id: 2,
    plantname: "PlantB",
    phonenumber: "324324324",
    photo: null,
    location: "Mumbai",
    created_at: "2024-01-03T00:00:00.000Z",
    updated_at: "2024-01-03T00:00:00.000Z",
    plantdata: [
      {
        id: 17,
        plantId: 2,
        type: "Coalstock",
        parameter: "FirstCol",
        umo: "MT",
        value: "20000",
        total: "11334",
        created_at: "2024-01-03T00:00:00.000Z",
        update_at: "2024-01-17T17:12:35.000Z",
      },
      {
        id: 18,
        plantId: 2,
        type: "Coalstock",
        parameter: "SecondCol",
        umo: "MT",
        value: "13988",
        total: "11334",
        created_at: "2024-01-03T00:00:00.000Z",
        update_at: "2024-01-26T17:12:40.000Z",
      },
      {
        id: 19,
        plantId: 2,
        type: "Coalreceipt",
        parameter: "FirstRe",
        umo: "MT",
        value: "13988",
        total: "6433",
        created_at: "2024-01-03T00:00:00.000Z",
        update_at: "2024-01-26T17:12:40.000Z",
      },
      {
        id: 20,
        plantId: 2,
        type: "Coalreceipt",
        parameter: "SecondRe",
        umo: "MT",
        value: "13988",
        total: "6433",
        created_at: "2024-01-03T00:00:00.000Z",
        update_at: "2024-01-26T17:12:40.000Z",
      },
      {
        id: 21,
        plantId: 2,
        type: "Coalconsumption",
        parameter: "secondType",
        umo: "MT",
        value: "13988",
        total: "1334",
        created_at: "2024-01-03T00:00:00.000Z",
        update_at: "2024-01-26T17:12:40.000Z",
      },
    ],
  },
];

export default function LineBarAreaChart({ newDataPr }) {
  const [newData, setNewData] = useState([]);
  const [booleanCoalStock, setBooleanCoalStock] = useState(false);
  const [booleanCoalReceipt, setBooleanCoalReceipt] = useState(false);
  const [booleanCoalConsumption, setBooleanCoalConsumption] = useState(false);

  const handleBoolean = (name) => {
    if (name == "coalStock") {
      setBooleanCoalStock(true);
      setBooleanCoalConsumption(false);
      setBooleanCoalReceipt(false);
    }
    if (name == "coalReceipt") {
      setBooleanCoalReceipt(true);
      setBooleanCoalStock(false);
      setBooleanCoalConsumption(false);
    }
    if (name == "coalConsumption") {
      setBooleanCoalConsumption(true);
      setBooleanCoalReceipt(false);
      setBooleanCoalStock(false);
    }
  };
  useEffect(() => {
    if (newDataPr?.length) {
      let updatedData = newDataPr?.map((item) => {
        const coalStock = item.plantdata.filter(
          (value) => value.type == "Coal Stock" && item.id === value.plantId
        );
        const coalStockTotal = coalStock.reduce((accum, current) => {
          return accum + Number(current.value);
        }, 0);

        const coalReceipt = item.plantdata.filter(
          (value) => value.type == "Coal Receipt" && item.id === value.plantId
        );
        const coalReceiptTotal = coalReceipt.reduce((accum, current) => {
          return accum + Number(current.value);
        }, 0);
        const coalConsuption = item.plantdata.filter(
          (value) =>
            value.type == "Coal Consumption" && item.id === value.plantId
        );
        const coalConsuptionTotal = coalConsuption.reduce((accum, current) => {
          return accum + Number(current.value);
        }, 0);
        let finalData = {
          // id: item.id,
          name: item.plantname,
          // location: item.location,
          coalStock: coalStockTotal,
          coalReceipt: coalReceiptTotal,
          coalConsumption: coalConsuptionTotal,
        };
        let stockData = {
          name: item.plantname,
          coalStock: coalStockTotal,
        };
        let receiptData = {
          name: item.plantname,
          coalReceipt: coalReceiptTotal,
        };
        let coalConsuptiontData = {
          name: item.plantname,
          coalConsumption: coalConsuptionTotal,
        };
        if (booleanCoalStock) {
          return stockData;
        } else if (booleanCoalReceipt) {
          return receiptData;
        } else if (booleanCoalConsumption) {
          return coalConsuptiontData;
        } else {
          return finalData;
        }
      });

      setNewData(updatedData);
    }
  }, [newDataPr, booleanCoalConsumption, booleanCoalReceipt, booleanCoalStock]);

  return (
    <div className="w-full  flex mt-10 data-grid">
      <ComposedChart
        width={800}
        height={400}
        data={newData}
        margin={{
          top: 20,
          right: 20,
          bottom: 20,
          left: 20,
        }}
      >
        <CartesianGrid stroke="#f5f5f5" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        {/* {coalConsuptionTotal && ( */}
        <Bar
          type="monotone"
          dataKey="coalConsumption"
          fill="#8884d8"
          stroke="#8884d8"
        />
        {/* )} */}
        <Bar dataKey="coalReceipt" barSize={20} fill="#413ea0" />
        <Bar type="monotone" dataKey="coalStock" stroke="#ff7300" />
        {/* <Scatter dataKey="cnt" fill="red" /> */}
      </ComposedChart>
      <div className="mt-16">
        <div
          className={
            booleanCoalStock ? `cursor-pointer` : "cursor-pointer opacity-40"
          }
        >
          <div className="progress-title">Coal Stock </div>

          <div
            className="progress  w-[300px]"
            onClick={() => handleBoolean("coalStock")}
          >
            <div
              className="progress-bar progress-bar-striped"
              role="progressbar"
              style={{ width: "50%" }}
              aria-valuenow="10"
              aria-valuemin="0"
              aria-valuemax="100"
            >
              50%
            </div>
          </div>
        </div>

        <div
          className={
            booleanCoalReceipt ? `cursor-pointer` : "cursor-pointer opacity-40"
          }
        >
          <div className="progress-title mt-5">Coal Receipt </div>
          <div
            className="progress w-[300px] "
            onClick={() => handleBoolean("coalReceipt")}
          >
            <div
              className="progress-bar progress-bar-striped"
              role="progressbar"
              style={{ width: "30%" }}
              aria-valuenow="10"
              aria-valuemin="0"
              aria-valuemax="100"
            >
              30%
            </div>
          </div>
        </div>
        <div
          className={
            booleanCoalConsumption
              ? `cursor-pointer`
              : " cursor-pointer opacity-40"
          }
        >
          <div className="progress-title mt-5">Coal Consumption </div>
          <div
            className="progress w-[300px] "
            onClick={() => handleBoolean("coalConsumption")}
          >
            <div
              className="progress-bar progress-bar-striped"
              role="progressbar"
              style={{ width: "20%" }}
              aria-valuenow="10"
              aria-valuemin="0"
              aria-valuemax="100"
            >
              20%
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
