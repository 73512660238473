import { reactIcons } from "./icons";

export const sideBarLinks = [
  {
    path: "/dashboard",
    title: "Dashboard",
    icon: reactIcons.dashboard,
  },
  {
    path: "/plant-list",
    title: "Stock Information",
    icon: reactIcons.user,
  },
  // {
  //   path: "/schedule",
  //   title: "Schedule",
  //   icon: reactIcons.user,
  // },
  // {
  //   path: "/credentials",
  //   title: "Credentials",
  //   icon: reactIcons.user,
  // },

  // {
  //   path: "/sessions",
  //   title: "Sessions",
  //   icon: reactIcons.list,
  // },
  // {
  //   path: "/edit-signature",
  //   title: "Edit Signature",
  //   icon: reactIcons.list,
  // },
  // {
  //   path: "/earnings",
  //   title: "Earnings",
  //   icon: reactIcons.dollar,
  // },
];
export const credentials = [
  { logo: "/images/autograph.png", title: "Signature" },
  { logo: "/images/gps.png", title: "Address" },
  { logo: "/images/stamp.png", title: "Commission and Seals" },
  { logo: "/images/approved.png", title: "Digital Certifcate" },
  {
    logo: "/images/achievements.png",
    title: "Certifications and Achievements",
  },
];

export const newDataPr = [
  {
    id: 55,
    plantname: "Mundra",
    phonenumber: "919586201169",
    timestamp: null,
    location: "Gujarat",
    created_at: "2023-05-20T10:23:15.192Z",
    updated_at: "2024-01-11T10:23:15.192Z",
    plantdata: [
      {
        id: 479,
        plantId: 55,
        type: "CoalStock",
        parameter: "Type A",
        umo: "MT",
        value: "20000",
        total: "0",
        created_at: "2023-05-20T10:23:15.554Z",
        update_at: "2024-01-11T10:23:15.554Z",
      },
      {
        id: 480,
        plantId: 55,
        type: "CoalConsumption",
        parameter: "Type A",
        umo: "MT",
        value: "17948",
        total: "0",
        created_at: "2023-03-12T10:23:15.578Z",
        update_at: "2024-01-11T10:23:15.578Z",
      },
      {
        id: 481,
        plantId: 55,
        type: "CoalStock",
        parameter: "Type C",
        umo: "MT",
        value: "12240",
        total: "0",
        created_at: "2023-08-05T10:23:15.594Z",
        update_at: "2024-01-11T10:23:15.594Z",
      },
      {
        id: 482,
        plantId: 55,
        type: "CoalReceipt",
        parameter: "Type D",
        umo: "MT",
        value: "14434",
        total: "0",
        created_at: "2023-10-18T10:23:15.554Z",
        update_at: "2024-01-11T10:23:15.554Z",
      },
      {
        id: 483,
        plantId: 55,
        type: "CoalStock",
        parameter: "Type B",
        umo: "MT",
        value: "17761",
        total: "0",
        created_at: "2023-01-30T10:23:15.554Z",
        update_at: "2024-01-11T10:23:15.554Z",
      },
      {
        id: 484,
        plantId: 55,
        type: "CoalConsumption",
        parameter: "Type D",
        umo: "MT",
        value: "14288",
        total: "0",
        created_at: "2023-06-15T10:23:15.618Z",
        update_at: "2024-01-11T10:23:15.618Z",
      },
      {
        id: 485,
        plantId: 55,
        type: "CoalConsumption",
        parameter: "Type B",
        umo: "MT",
        value: "14086",
        total: "0",
        created_at: "2023-11-22T10:23:15.618Z",
        update_at: "2024-01-11T10:23:15.618Z",
      },
      {
        id: 486,
        plantId: 55,
        type: "CoalConsumption",
        parameter: "Type C",
        umo: "MT",
        value: "15445",
        total: "0",
        created_at: "2023-04-08T10:23:15.618Z",
        update_at: "2024-01-11T10:23:15.618Z",
      },
      {
        id: 487,
        plantId: 55,
        type: "CoalStock",
        parameter: "Type D",
        umo: "MT",
        value: "14752",
        total: "0",
        created_at: "2023-09-29T10:23:15.618Z",
        update_at: "2024-01-11T10:23:15.618Z",
      },
      {
        id: 488,
        plantId: 55,
        type: "CoalReceipt",
        parameter: "Type A",
        umo: "MT",
        value: "16547",
        total: "0",
        created_at: "2023-07-03T10:23:15.618Z",
        update_at: "2024-01-11T10:23:15.618Z",
      },
      {
        id: 489,
        plantId: 55,
        type: "CoalReceipt",
        parameter: "Type B",
        umo: "MT",
        value: "15839",
        total: "0",
        created_at: "2023-02-14T10:23:15.619Z",
        update_at: "2024-01-11T10:23:15.619Z",
      },
      {
        id: 490,
        plantId: 55,
        type: "CoalReceipt",
        parameter: "Type C",
        umo: "MT",
        value: "12716",
        total: "0",
        created_at: "2023-12-10T10:23:15.619Z",
        update_at: "2024-01-11T10:23:15.619Z",
      },
    ],
  },
  {
    id: 52,
    plantname: "Tiroda",
    phonenumber: "919737933269",
    timestamp: null,
    location: "Gujarat",
    created_at: "2023-07-18T10:12:53.947Z",
    updated_at: "2024-01-11T10:12:53.947Z",
    plantdata: [
      {
        id: 444,
        plantId: 52,
        type: "CoalStock",
        parameter: "Type C",
        umo: "MT",
        value: "16869",
        total: "0",
        created_at: "2023-07-18T10:12:54.088Z",
        update_at: "2024-01-11T10:12:54.088Z",
      },
      {
        id: 445,
        plantId: 52,
        type: "CoalStock",
        parameter: "Type D",
        umo: "MT",
        value: "16113",
        total: "0",
        created_at: "2023-09-05T10:12:54.089Z",
        update_at: "2024-01-11T10:12:54.089Z",
      },
      {
        id: 446,
        plantId: 52,
        type: "CoalReceipt",
        parameter: "Type A",
        umo: "MT",
        value: "13815",
        total: "0",
        created_at: "2023-11-23T10:12:54.089Z",
        update_at: "2024-01-11T10:12:54.089Z",
      },
      {
        id: 447,
        plantId: 52,
        type: "CoalStock",
        parameter: "Type B",
        umo: "MT",
        value: "12798",
        total: "0",
        created_at: "2023-05-30T10:12:54.088Z",
        update_at: "2024-01-11T10:12:54.088Z",
      },
      {
        id: 448,
        plantId: 52,
        type: "CoalStock",
        parameter: "Type A",
        umo: "MT",
        value: "20000",
        total: "0",
        created_at: "2023-02-14T10:12:54.088Z",
        update_at: "2024-01-11T10:12:54.088Z",
      },
      {
        id: 449,
        plantId: 52,
        type: "CoalReceipt",
        parameter: "Type B",
        umo: "MT",
        value: "17589",
        total: "0",
        created_at: "2023-12-10T10:12:54.089Z",
        update_at: "2024-01-11T10:12:54.089Z",
      },
      {
        id: 450,
        plantId: 52,
        type: "CoalConsumption",
        parameter: "Type A",
        umo: "MT",
        value: "17962",
        total: "0",
        created_at: "2023-08-25T10:12:54.089Z",
        update_at: "2024-01-11T10:12:54.089Z",
      },
      {
        id: 451,
        plantId: 52,
        type: "CoalReceipt",
        parameter: "Type C",
        umo: "MT",
        value: "13470",
        total: "0",
        created_at: "2023-04-01T10:12:54.089Z",
        update_at: "2024-01-11T10:12:54.089Z",
      },
      {
        id: 452,
        plantId: 52,
        type: "CoalReceipt",
        parameter: "Type D",
        umo: "MT",
        value: "14852",
        total: "0",
        created_at: "2023-06-15T10:12:54.089Z",
        update_at: "2024-01-11T10:12:54.089Z",
      },
      {
        id: 453,
        plantId: 52,
        type: "CoalConsumption",
        parameter: "Type B",
        umo: "MT",
        value: "13813",
        total: "0",
        created_at: "2023-03-12T10:12:54.089Z",
        update_at: "2024-01-11T10:12:54.089Z",
      },
      {
        id: 454,
        plantId: 52,
        type: "CoalConsumption",
        parameter: "Type C",
        umo: "MT",
        value: "14206",
        total: "0",
        created_at: "2023-10-18T10:12:54.089Z",
        update_at: "2024-01-11T10:12:54.089Z",
      },
      {
        id: 455,
        plantId: 52,
        type: "CoalConsumption",
        parameter: "Type D",
        umo: "MT",
        value: "16936",
        total: "0",
        created_at: "2023-01-30T10:12:54.089Z",
        update_at: "2024-01-11T10:12:54.089Z",
      },
    ],
  },
  {
    id: 49,
    plantname: "Kawai",
    phonenumber: "919630604831",
    timestamp: null,
    location: "Gujarat",
    created_at: "2023-09-15T10:09:35.257Z",
    updated_at: "2024-01-11T10:09:35.257Z",
    plantdata: [
      {
        id: 408,
        plantId: 49,
        type: "CoalStock",
        parameter: "Type A",
        umo: "MT",
        value: "30000",
        total: "0",
        created_at: "2023-11-29T10:09:35.459Z",
        update_at: "2024-01-11T10:09:35.459Z",
      },
      {
        id: 409,
        plantId: 49,
        type: "CoalStock",
        parameter: "Type D",
        umo: "MT",
        value: "16047",
        total: "0",
        created_at: "2023-02-02T10:09:35.479Z",
        update_at: "2024-01-11T10:09:35.479Z",
      },
      {
        id: 410,
        plantId: 49,
        type: "CoalStock",
        parameter: "Type C",
        umo: "MT",
        value: "300",
        total: "0",
        created_at: "2023-03-22T10:09:35.478Z",
        update_at: "2024-01-11T10:09:35.478Z",
      },
      {
        id: 411,
        plantId: 49,
        type: "CoalReceipt",
        parameter: "Type B",
        umo: "MT",
        value: "3500",
        total: "0",
        created_at: "2023-08-08T10:09:35.498Z",
        update_at: "2024-01-11T10:09:35.498Z",
      },
      {
        id: 412,
        plantId: 49,
        type: "CoalStock",
        parameter: "Type B",
        umo: "MT",
        value: "1600",
        total: "0",
        created_at: "2023-04-15T10:09:35.460Z",
        update_at: "2024-01-11T10:09:35.460Z",
      },
      {
        id: 413,
        plantId: 49,
        type: "CoalReceipt",
        parameter: "Type A",
        umo: "MT",
        value: "14492",
        total: "0",
        created_at: "2023-09-29T10:09:35.481Z",
        update_at: "2024-01-11T10:09:35.481Z",
      },
      {
        id: 414,
        plantId: 49,
        type: "CoalReceipt",
        parameter: "Type C",
        umo: "MT",
        value: "15951",
        total: "0",
        created_at: "2023-06-20T10:09:35.498Z",
        update_at: "2024-01-11T10:09:35.498Z",
      },
      {
        id: 415,
        plantId: 49,
        type: "CoalReceipt",
        parameter: "Type D",
        umo: "MT",
        value: "17517",
        total: "0",
        created_at: "2023-02-14T10:09:35.498Z",
        update_at: "2024-01-11T10:09:35.498Z",
      },
      {
        id: 416,
        plantId: 49,
        type: "CoalConsumption",
        parameter: "Type B",
        umo: "MT",
        value: "15006",
        total: "0",
        created_at: "2023-03-28T10:09:35.498Z",
        update_at: "2024-01-11T10:09:35.498Z",
      },
      {
        id: 417,
        plantId: 49,
        type: "CoalConsumption",
        parameter: "Type A",
        umo: "MT",
        value: "15306",
        total: "0",
        created_at: "2023-04-22T10:09:35.498Z",
        update_at: "2024-01-11T10:09:35.498Z",
      },
      {
        id: 418,
        plantId: 49,
        type: "CoalConsumption",
        parameter: "Type C",
        umo: "MT",
        value: "26550",
        total: "0",
        created_at: "2023-12-18T10:09:35.499Z",
        update_at: "2024-01-11T10:09:35.499Z",
      },
      {
        id: 419,
        plantId: 49,
        type: "CoalConsumption",
        parameter: "Type D",
        umo: "MT",
        value: "12344",
        total: "0",
        created_at: "2023-07-12T10:09:35.499Z",
        update_at: "2024-01-11T10:09:35.499Z",
      },
    ],
  },
  {
    id: 38,
    plantname: "Raipur",
    phonenumber: "919909641020",
    timestamp: null,
    location: "Gujarat",
    created_at: "2023-06-20T13:32:22.904Z",
    updated_at: "2024-01-10T13:32:22.904Z",
    plantdata: [
      {
        id: 280,
        plantId: 38,
        type: "CoalStock",
        parameter: "Type A",
        umo: "MT",
        value: "20000",
        total: "0",
        created_at: "2023-09-15T13:32:23.004Z",
        update_at: "2024-01-10T13:32:23.004Z",
      },
      {
        id: 281,
        plantId: 38,
        type: "CoalStock",
        parameter: "Type D",
        umo: "MT",
        value: "13424",
        total: "0",
        created_at: "2023-02-14T13:32:23.005Z",
        update_at: "2024-01-10T13:32:23.005Z",
      },
      {
        id: 282,
        plantId: 38,
        type: "CoalStock",
        parameter: "Type C",
        umo: "MT",
        value: "15153",
        total: "0",
        created_at: "2023-12-18T13:32:23.005Z",
        update_at: "2024-01-10T13:32:23.005Z",
      },
      {
        id: 283,
        plantId: 38,
        type: "CoalStock",
        parameter: "Type B",
        umo: "MT",
        value: "12552",
        total: "0",
        created_at: "2023-04-22T13:32:23.005Z",
        update_at: "2024-01-10T13:32:23.005Z",
      },
      {
        id: 284,
        plantId: 38,
        type: "CoalReceipt",
        parameter: "Type B",
        umo: "MT",
        value: "63264",
        total: "0",
        created_at: "2023-03-28T13:32:23.005Z",
        update_at: "2024-01-10T13:32:23.005Z",
      },
      {
        id: 285,
        plantId: 38,
        type: "CoalReceipt",
        parameter: "Type A",
        umo: "MT",
        value: "15306",
        total: "0",
        created_at: "2023-06-20T13:32:23.005Z",
        update_at: "2024-01-10T13:32:23.005Z",
      },
      {
        id: 286,
        plantId: 38,
        type: "CoalConsumption",
        parameter: "Type D",
        umo: "MT",
        value: "15841",
        total: "0",
        created_at: "2023-07-12T13:32:23.005Z",
        update_at: "2024-01-10T13:32:23.005Z",
      },
      {
        id: 287,
        plantId: 38,
        type: "CoalReceipt",
        parameter: "Type C",
        umo: "MT",
        value: "14695",
        total: "0",
        created_at: "2023-02-02T13:32:23.005Z",
        update_at: "2024-01-10T13:32:23.005Z",
      },
    ],
  },
];
