import React from "react";
import { FaUser } from "react-icons/fa";
import {
  BiLogOutCircle,
  BiDollarCircle,
  BiChevronLeft,
  BiChevronRight,
} from "react-icons/bi";
import { AiOutlineUnorderedList } from "react-icons/ai";
import { MdSpaceDashboard } from "react-icons/md";
import { HiMenuAlt1 } from "react-icons/hi";
import { BsBoxArrowInRight } from "react-icons/bs";
import { FaEye } from "react-icons/fa";
export const reactIcons = {
  user: <FaUser />,
  logout: <BiLogOutCircle />,
  dollar: <BiDollarCircle />,
  list: <AiOutlineUnorderedList />,
  arrowleft: <BiChevronLeft />,
  arrowright: <BiChevronRight />,
  dashboard: <MdSpaceDashboard />,
  menu: <HiMenuAlt1 />,
  openArrow: <BsBoxArrowInRight />,
  eye: <FaEye />,
};
