import React, { useEffect, useState } from "react";
import { FiAward } from "react-icons/fi";
import { GiThrownCharcoal } from "react-icons/gi";
import { BiDollarCircle } from "react-icons/bi";
import { AiFillStar } from "react-icons/ai";
import { MdComputer, MdThumbUp } from "react-icons/md";
import { NavLink, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { LinearProgress } from "@mui/material";
import ReactApexChart from "react-apexcharts";
import LineBarAreaChart from "./LineBarAreaChart";
import { BsBasket3Fill } from "react-icons/bs";
import { FaReceipt } from "react-icons/fa";
import axios from "axios";
import { getReq } from "../utils/ApiHandler";
import moment from "moment";
import { Footer } from "./Footer";
import { FaFireBurner } from "react-icons/fa6";
import { Chart } from "react-google-charts";
import Loader from "./Loader";
import { newDataPr } from "../utils/constants";
import { ComposedChart, Legend, Tooltip, XAxis, YAxis } from "recharts";
const data = [
  ["Year", "Coal Stock", "Coal Receipt", "Coal Consuption"],
  ["2023", 20000, 13815, 16369],
  ["2024", 65780, 56724, 61212],
  // ["2016", 660, 1120, 300],
  // ["2017", 1030, 540, 350],
];
const options1 = {
  chart: {
    title: "",
    subtitle: "Coal Stock, Coal Receipt, and Coal Consuption: 2024",
  },
};

let dummyData = [
  ["01/01/2023", 20000],
  ["02/01/2023", 15000],
  ["03/01/2023", 12745],
  ["04/01/2023", 45120],
  ["05/01/2023", 74521],
  ["06/01/2023", 12450],
  ["07/01/2023", 69523],
  ["08/01/2023", 47850],
  ["09/01/2023", 12456],
  ["10/01/2023", 74852],
  ["11/01/2023", 12456],
  ["12/01/2023", 36520],
];

const DashboardLanding = () => {
  const navigate = useNavigate();
  const [chartData, setChartData] = useState([]);
  const [totalCount, setTotalCount] = useState([]);
  const [coalStockData, setCoalStockData] = useState([]);
  const [coalReceiptData, setCoalReceiptData] = useState([]);
  const [coalConsuptionData, setCoalConsuptionData] = useState([]);
  const [thirdChartData, setThirdChartData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalChart, setTotalChart] = useState([]);
  const [thirdPlantName, setThirdPlantName] = useState([]);
  const [kpiList, setKpiList] = useState(false);
  const [plantData, setPlantData] = useState(false);
  const [activeTabName, setActiveTabName] = useState("trend");
  const [acivePlant, setActivePlant] = useState();
  const [activeKPI, setActiveKPI] = useState();
  const [plantName, setPlantName] = useState();
  const [KPIName, setKPIName] = useState();
  const [tableData, setTableData] = useState([]);
  const [allChartData, setAllChartData] = useState([]);
  const [newData1, setNewData1] = useState([]);
  // const [rows, setRows] = useState([...data]);
  const kpi = [
    "GrossGeneration",
    "PLF",
    "APC",
    "Coal Stock",
    "Coal Receipt",
    "Coal Consumption",
  ];
  let totalChartDataNewm = [];
  function getFullName(params) {
    return `${params.row.first_name || ""} ${params.row.last_name || ""}`;
  }
  const handleFilter = (itemId) => {
    return setRows(rows.filter((item) => item.id !== itemId));
  };

  const [selection, setSelection] = useState("one_year");

  // const updateData = (timeline) => {
  //   setSelection(timeline);

  //   switch (timeline) {
  //     case "one_month":
  //       ApexCharts.exec(
  //         "area-datetime",
  //         "zoomX",
  //         new Date("28 Jan 2013").getTime(),
  //         new Date("27 Feb 2013").getTime()
  //       );
  //       break;
  //     case "six_months":
  //       ApexCharts.exec(
  //         "area-datetime",
  //         "zoomX",
  //         new Date("27 Sep 2012").getTime(),
  //         new Date("27 Feb 2013").getTime()
  //       );
  //       break;
  //     case "one_year":
  //       ApexCharts.exec(
  //         "area-datetime",
  //         "zoomX",
  //         new Date("27 Feb 2012").getTime(),
  //         new Date("27 Feb 2013").getTime()
  //       );
  //       break;
  //     case "ytd":
  //       ApexCharts.exec(
  //         "area-datetime",
  //         "zoomX",
  //         new Date("01 Jan 2013").getTime(),
  //         new Date("27 Feb 2013").getTime()
  //       );
  //       break;
  //     case "all":
  //       ApexCharts.exec(
  //         "area-datetime",
  //         "zoomX",
  //         new Date("23 Jan 2012").getTime(),
  //         new Date("27 Feb 2013").getTime()
  //       );
  //       break;
  //     default:
  //   }
  // };
  const getData = async () => {
    setIsLoading(true);
    await getReq("/v1/data").then((res) => {
      if (res.status) {
        setChartData(res.data.data);
        let data = res.data.data;
        data.map((item) => {
          newData1.push(item.plantname);
        });
        console.log(res.data.data, "ddddd");
        setIsLoading(false);
      } else {
        console.log("error");
        setIsLoading(false);
      }
    });
  };

  const getTotalCount = () => {
    setIsLoading(true);
    getReq("/v1/count").then((res) => {
      if (res.status) {
        setTotalCount(res.data.data);
        setIsLoading(false);
      } else {
        console.log("error");
        setIsLoading(false);
      }
    });
  };
  useEffect(() => {
    getData();
    //   getTotalCount();
  }, []);

  // useEffect(() => {
  //   if (chartData?.length) {
  //     let dd = [];
  //     let dd1 = [];
  //     let dd2 = [];
  //     chartData.plantdata?.map((item) => {
  //       if (item.type == "Coal Stock") {
  //         dd.push([
  //           moment.utc(item.created_at).format("MM/DD/YYYY"),
  //           Number(item.value),
  //         ]);
  //         setCoalStockData(dd);
  //       } else if (item.type == "Coal Receipt") {
  //         dd1.push([
  //           moment.utc(item.created_at).format("MM/DD/YYYY"),
  //           Number(item.value),
  //         ]);
  //         setCoalReceiptData(dd1);
  //       } else if (item.type == "Coal Consumption") {
  //         dd2.push([
  //           moment.utc(item.created_at).format("MM/DD/YYYY"),
  //           Number(item.value),
  //         ]);
  //         setCoalConsuptionData(dd2);
  //       }
  //       updateData("one_month");
  //     });
  //   }
  // }, [chartData]);
  console.log(coalStockData, "coalStockDAta");
  const series = [
    {
      name: "coalStock",
      data: [
        [1674487549000, 30095],
        [1674573949000, 31034],
        [1674660349000, 31018],
        [1674746749000, 3100],
        [1675092349000, 30095],
        [1675178749000, 31024],
        [1675265149000, 31029],
        [1675351549000, 31085],
        [1675610749000, 31086],
        [1675697149000, 32028],
        [1675783549000, 3201],
        [1675869949000, 32065],
        [1675956349000, 32021],
        [1676215549000, 32035],
        [1676301949000, 32044],
        [1676388349000, 32046],
        [1676474749000, 32086],
        [1676561149000, 32075],
        [1676906749000, 32054],
        [1676993149000, 32033],
        [1677079549000, 32097],
        [1677165949000, 33041],
        [1677425149000, 33027],
        [1677511549000, 33027],
        [1677597949000, 32089],
        [1677684349000, 3301],
        [1677684349000, 33073],
        [1677943549000, 33022],
        [1678029949000, 31099],
        [1678116349000, 32041],
        [1678202749000, 33005],
        [1678289149000, 33064],
        [1678548349000, 33056],
        [1678634749000, 34022],
        [1678721149000, 33077],
        [1678807549000, 34017],
        [1678893949000, 33082],
        [1679153149000, 34051],
        [1679239549000, 33016],
        [1679325949000, 33056],

        [1679412349000, 33071],
        [1679498749000, 33081],
        [1679757949000, 3404],
        [1679844349000, 34063],
        [1679930749000, 34046],
        [1680017149000, 34048],
        [1680103549000, 34031],
        [1680362749000, 3407],
        [1680449149000, 34031],
        [1680535549000, 33046],
        [1680621949000, 33059],
        [1680970127000, 33022],
        [1681056527000, 32061],
        [1681142927000, 33001],
        [1681229327000, 33055],
        [1681315727000, 33018],
        [1681574927000, 32084],
        [1681661327000, 33084],
        [1681747727000, 33039],
        [1681834127000, 32091],
        [1681920527000, 33006],
        [1682179727000, 32062],
        [1682266127000, 3204],
        [1682352527000, 33013],
        [1682438927000, 33026],
        [1682525327000, 33058],
        [1682784527000, 33055],
        [1682957327000, 33077],
        [1683043727000, 33032],
        [1683130127000, 32061],
        [1683389327000, 32052],
        [1683475727000, 32067],
        [1683562127000, 32052],
        [1683648527000, 31092],
        [1683734927000, 3202],
        [1683994127000, 32023],
        [1684080527000, 32033],
        [1684166927000, 32036],
        [1684253327000, 32001],
        [1684339727000, 31031],
        [1684598927000, 32001],
        [1684685327000, 32001],
        [1685981327000, 31069],
        [1686067727000, 31032],
        [1686154127000, 31065],
        [1686413327000, 31013],
        [1686499727000, 31077],
        [1687277327000, 31023],
        [1687363727000, 31057],
        [1688314127000, 32051],

        [1688486927000, 32053],
        [1689005327000, 3002],
        [1689091727000, 30014],
        [1689523727000, 30065],
        [1690042127000, 30064],
        [1690214927000, 30033],
        [1690733327000, 30088],
        [1690819727000, 30069],
        [1690906127000, 30058],
        [1691510927000, 32065],
        [1691597327000, 32064],
        [1692115727000, 33065],
        [1692202127000, 3308],
        [1692461327000, 33092],
        [1693066127000, 32032],
        [1693152527000, 32006],
        [1694275727000, 32013],
        [1694880527000, 33041],
        [1694966927000, 32057],
        [1695744527000, 3208],
        [1695917327000, 32044],
        [1696435727000, 32047],
        [1696867727000, 31051],
        [1696954127000, 31078],
        [1697904527000, 33049],
        [1698163727000, 3304],
        [1698250127000, 34001],
        [1699286927000, 33047],
        [1699373327000, 32098],
        [1700064527000, 32065],
        [1700323727000, 32092],
        [1701101327000, 33088],
        [1701187727000, 34009],
        [1701878927000, 35048],
        [1702138127000, 35075],
        [1702742927000, 37042],
        [1702829327000, 37049],
        [1703520527000, 37055],
        [1703606927000, 3703],
        [1704298127000, 38013],
        [1704557327000, 37094],
        [1704643727000, 38014],
        [1704730127000, 38066],
        [1704816527000, 38062],
        [1704902927000, 38009],
        [1705162127000, 38016],
        [1705248527000, 38015],
        [1705334927000, 37088],
      ],
    },
    {
      name: "coalReceipt",
      data: [
        [1674487549000, 20000],
        [1674573949000, 410],
        [1674660349000, 2210],
        [1674746749000, 30120],
        [1675092349000, 7451],
        [1675178749000, 45612],
        [1675265149000, 30012],
        [1675351549000, 4512],
        [1675610749000, 4589],
        [1675697149000, 12456],
        [1675783549000, 78956],
        [1675869949000, 1200],
        [1675956349000, 785],
        [1676215549000, 550],
        [1676301949000, 4512],
        [1676388349000, 7840],
        [1676474749000, 3275],
        [1676561149000, 3054],
        [1676906749000, 32330],
        [1676993149000, 32097],
        [1677079549000, 31141],
        [1677079549000, 30027],
        [1677165949000, 31027],
        [1677425149000, 30289],
        [1677511549000, 3031],
        [1677597949000, 3073],
        [1677684349000, 3422],
        [1677684349000, 31990],
        [1677943549000, 32410],
        [1678029949000, 33050],
        [1678116349000, 34640],
        [1678202749000, 2356],
        [1678289149000, 3422],
        [1678548349000, 3387],
        [1678634749000, 3017],
        [1678721149000, 32082],
        [1678807549000, 34051],
        [1678893949000, 33416],
        [1679153149000, 33156],
        [1679239549000, 33871],
        [1679325949000, 3181],
        [1679412349000, 3404],
        [1679498749000, 34663],
        [1679757949000, 34446],
        [1679844349000, 30048],
        [1679930749000, 3031],
        [1680017149000, 34789],
        [1680103549000, 34031],
        [1680362749000, 33446],
        [1680449149000, 33059],
        [1680535549000, 37822],
        [1680621949000, 37451],
        [1680970127000, 37801],
        [1681056527000, 39855],
        [1681142927000, 30008],
        [1681229327000, 37454],
        [1681315727000, 34504],
        [1681574927000, 3049],
        [1681661327000, 3781],
        [1681747727000, 30006],
        [1681834127000, 37452],
        [1681920527000, 3204],
        [1682179727000, 37453],
        [1682266127000, 3306],
        [1682352527000, 34558],
        [1682438927000, 34555],
        [1682525327000, 33777],
        [1682784527000, 3476],
        [1682957327000, 33032],
        [1683043727000, 361],
        [1683130127000, 3452],
        [1683389327000, 32067],
        [1683475727000, 32752],
        [1683562127000, 31092],
        [1683648527000, 3220],
        [1683734927000, 32003],
        [1683994127000, 32345],
        [1684080527000, 32745],
        [1684166927000, 3278],
        [1684253327000, 31745],
        [1684339727000, 32745],
        [1684598927000, 32000],
        [1684685327000, 32025],
        [1685981327000, 10412],
        [1686067727000, 1106],
        [1686154127000, 15005],
        [1686413327000, 2900],
        [1686499727000, 31455],
        [1687277327000, 20002],
        [1687363727000, 30001],
        [1688314127000, 30000],
        [1688486927000, 3145],
        [1689005327000, 31452],
        [1689091727000, 31005],
        [1689523727000, 3453],
        [1690042127000, 3077],
        [1690214927000, 4579],
        [1690733327000, 6700],
        [1690819727000, 3409],
        [1690906127000, 3463],
        [1691510927000, 3489],
        [1691597327000, 31099],
        [1692115727000, 31023],
        [1692202127000, 31057],
        [1692461327000, 30084],
        [1693066127000, 31007],
        [1693152527000, 31041],
        [1694275727000, 31017],
        [1694880527000, 32037],
        [1694966927000, 32019],
        [1695744527000, 32251],
        [1695917327000, 32053],
        [1696435727000, 31037],
        [1696867727000, 30043],
        [1696954127000, 30044],
        [1697904527000, 3002],
        [1698163727000, 30014],
        [1698250127000, 30065],
        [1699286927000, 3004],
        [1699373327000, 30065],
        [1700064527000, 31043],
        [1700323727000, 31089],
        [1701101327000, 31038],
        [1701187727000, 30064],
        [1701878927000, 30002],
        [1702138127000, 30033],
        [1702742927000, 30095],
        [1702829327000, 3189],
        [1703520527000, 31001],
        [1703606927000, 30088],
        [1704298127000, 30069],
        [1704557327000, 3058],
        [1704643727000, 3202],
        [1704730127000, 32014],
        [1704816527000, 32137],
        [1704902927000, 32051],
        [1705162127000, 32765],
        [1705248527000, 32764],
        [1705334927000, 32827],
      ],
    },
    {
      name: "coalConsumption",
      data: [
        [1674487549000, 32002],
        [1674573949000, 32014],
        [1674660349000, 32037],
        [1674746749000, 32051],
        [1675092349000, 32065],
        [1675178749000, 32064],
        [1675265149000, 32027],
        [1675351549000, 3201],
        [1675610749000, 32091],
        [1675697149000, 33065],
        [1675783549000, 3308],
        [1675869949000, 33092],
        [1675956349000, 33075],
        [1676215549000, 33084],
        [1676301949000, 3305],
        [1676388349000, 32026],
        [1676474749000, 32032],
        [1676561149000, 32006],
        [1676906749000, 31096],
        [1676993149000, 31046],
        [1677079549000, 31027],
        [1677079549000, 31043],
        [1677165949000, 32026],
        [1677425149000, 32079],
        [1677511549000, 32046],
        [1677597949000, 32013],
        [1677684349000, 32043],
        [1677684349000, 32042],
        [1677943549000, 32081],
        [1678029949000, 33034],
        [1678116349000, 33041],
        [1678202749000, 32057],
        [1678289149000, 33012],
        [1678548349000, 34053],
        [1678634749000, 33083],
        [1678721149000, 33041],
        [1678807549000, 3209],
        [1678893949000, 32053],
        [1679153149000, 3208],
        [1679239549000, 32044],
        [1679325949000, 32062],
        [1679412349000, 32057],
        [1679498749000, 3206],
        [1679757949000, 32068],
        [1679844349000, 32047],
        [1679930749000, 32023],
        [1680017149000, 31068],
        [1680103549000, 31051],
        [1680362749000, 31078],
        [1680449149000, 31094],
        [1680535549000, 32033],
        [1680621949000, 33024],
        [1680970127000, 33044],
        [1681056527000, 33048],
        [1681142927000, 33024],
        [1681229327000, 33049],
        [1681315727000, 33031],
        [1681574927000, 33036],
        [1681661327000, 3304],
        [1681747727000, 34001],
        [1681834127000, 34002],
        [1681920527000, 34036],
        [1682179727000, 34039],
        [1682266127000, 34024],
        [1682352527000, 34039],
        [1682438927000, 33047],
        [1682525327000, 32098],
        [1682784527000, 3209],
        [1682957327000, 3207],
        [1683043727000, 32054],
        [1683130127000, 32023],
        [1683389327000, 32064],
        [1683475727000, 32065],
        [1683562127000, 32092],
        [1683648527000, 32064],
        [1683734927000, 32084],
        [1683994127000, 3304],
        [1684080527000, 3303],
        [1684166927000, 33018],
        [1684253327000, 33088],
        [1684339727000, 34009],
        [1684598927000, 34061],
        [1684685327000, 3407],
        [1685981327000, 3503],
        [1686067727000, 3504],
        [1686154127000, 35014],
        [1686413327000, 35048],
        [1686499727000, 35075],
        [1687277327000, 35054],
        [1687363727000, 35096],
        [1688314127000, 35053],
        [1688486927000, 37056],
        [1689005327000, 37042],
        [1689091727000, 37049],
        [1689523727000, 38009],
        [1690042127000, 37087],
        [1690214927000, 37071],
        [1690733327000, 37053],
        [1690819727000, 37055],
        [1690906127000, 3703],
        [1691510927000, 3609],
        [1691597327000, 37068],
        [1692115727000, 38034],
        [1692202127000, 37075],
        [1692461327000, 38013],
        [1693066127000, 37094],
        [1693152527000, 38014],
        [1694275727000, 38066],
        [1694880527000, 38062],
        [1694966927000, 38009],
        [1695744527000, 38016],
        [1695917327000, 38015],
        [1696435727000, 37088],
        [1696867727000, 37073],
        [1696954127000, 37098],
        [1697904527000, 37095],
        [1698163727000, 38025],
        [1698250127000, 3801],
        [1699286927000, 38032],
        [1699373327000, 38024],
        [1700064527000, 38052],
        [1700323727000, 37094],
        [1701101327000, 37083],
        [1701187727000, 38034],
        [1701878927000, 3801],
        [1702138127000, 38051],
        [1702742927000, 3804],
        [1702829327000, 38007],
        [1703520527000, 39012],
        [1703606927000, 38064],
        [1704298127000, 38089],
        [1704557327000, 38081],
        [1704643727000, 38061],
        [1704730127000, 38063],
        [1704816527000, 38099],
        [1704902927000, 38077],
        [1705162127000, 38034],
        [1705248527000, 38055],
        [1705334927000, 38011],
      ],
    },
  ];
  const [thirdData, setThirdData] = useState([]);

  useEffect(() => {
    // setIsLoading(true);
    // getReq("/v1/line/chart").then((res) => {
    //   if (res.status) {
    //     let data = res.data.data;
    //     let newData = data.map((value) => value.data_summary);
    //     let joinData = newData.join();
    //     let pairsArray = joinData.split(", ");

    //     // Initialize an empty object to store key-value pairs
    //     let keyValuePairs = {};

    //     // Iterate over each key-value pair string in the array
    //     pairsArray.forEach((pair) => {
    //       // Split each key-value pair based on the colon (:) separator
    //       let parts = pair.split(":");

    //       // Extract key, value, and date
    //       let key = parts[0].trim();
    //       let value = parseInt(parts[1].trim()); // Assuming the value is an integer, you can adjust accordingly
    //       let date = parts[2].trim();

    //       // Check if the key already exists, if yes, push the value and date to an array
    //       if (key in keyValuePairs) {
    //         keyValuePairs[key].push({ value, date });
    //       } else {
    //         // If the key doesn't exist, create a new array with the value and date
    //         keyValuePairs[key] = [{ value, date }];
    //       }
    //     });

    //     // console.log(keyValuePairs, "keyValuePairs");
    //     setIsLoading(false);
    //   } else {
    //     console.log("error");
    //     setIsLoading(false);
    //   }
    // });
    setIsLoading(true);
    getReq("/v1/total/chart").then((res) => {
      if (res.status) {
        let ddd = [];
        let ddd1 = [];
        let ddd2 = [];
        let data = res.data.data;
        let plantname = [];

        data.map((name) => {
          !plantname.includes(name.Plantname) && plantname.push(name.Plantname);
        });
        setThirdPlantName(plantname);

        let dd = data.filter((item) => item.Type == "Coal Consumption");
        dd?.map((value) => {
          let bb = value.Data_Summary.split(",");
          let newArray = bb.map(Number);
          let total = newArray.reduce((accum, value) => accum + value);

          ddd.push(total);
        });

        let dd1 = data.filter((item) => item.Type == "Coal Receipt");
        dd1.map((value) => {
          let bb1 = value.Data_Summary.split(",");
          let newArray1 = bb1.map(Number);
          let total1 = newArray1.reduce((accum, value) => accum + value);
          ddd1.push(total1);
          // ddd1.push({
          //   name: !dd1.includes(value.Type) ? value.Type : "",
          //   data: [total1],
          // });
        });
        let dd2 = data.filter((item) => item.Type == "Coal Stock");
        dd2.map((value) => {
          let bb2 = value.Data_Summary.split(",");
          let newArray2 = bb2.map(Number);
          let total2 = newArray2.reduce((accum, value) => accum + value);
          ddd2.push(total2);
          // ddd2.push({
          //   name: !dd2.includes(value.Type) ? value.Type : "",
          //   data: [total2],
          // });
        });
        let obj = {
          name: "Coal Stock",
          data: ddd,
        };
        let obj1 = {
          name: "Coal Receipt",
          data: ddd1,
        };
        let obj2 = {
          name: "Coal Consumption",
          data: ddd2,
        };
        setThirdData([obj, obj1, obj2]);
        setIsLoading(false);
      } else {
        console.log("error");
        setIsLoading(false);
      }
    });
  }, []);
  const optionsNew = {
    chart: {
      id: "area-datetime",
      type: "area",
      height: 350,
      zoom: {
        autoScaleYaxis: true,
      },
    },
    annotations: {
      yaxis: [
        {
          y: 30,
          borderColor: "#999",
          label: {
            show: true,
            text: "Support",
            style: {
              color: "#fff",
              background: "#00E396",
            },
          },
        },
      ],
      xaxis: [
        {
          x: new Date("14 Nov 2023").getTime(),
          borderColor: "#999",
          yAxisIndex: 0,
          label: {
            show: true,
            text: "Rally",
            style: {
              color: "#fff",
              background: "#775DD0",
            },
          },
        },
      ],
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 0,
      style: "hollow",
    },
    xaxis: {
      type: "datetime",
      min: new Date("01 Mar 2023").getTime(),
      tickAmount: 6,
    },
    tooltip: {
      x: {
        format: "dd MMM yyyy",
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 100],
      },
    },
  };

  //  setSelection('one_year')

  const updateData = (timeline) => {
    setSelection(timeline);

    switch (timeline) {
      case "one_month":
        ApexCharts.exec(
          "area-datetime",
          "zoomX",
          new Date("28 Jan 2023").getTime(),
          new Date("27 Feb 2023").getTime()
        );
        break;
      case "six_months":
        ApexCharts.exec(
          "area-datetime",
          "zoomX",
          new Date("27 Sep 2023").getTime(),
          new Date("27 Feb 2024").getTime()
        );
        break;
      case "one_year":
        ApexCharts.exec(
          "area-datetime",
          "zoomX",
          new Date("27 Feb 2012").getTime(),
          new Date("27 Feb 2013").getTime()
        );
        break;
      case "ytd":
        ApexCharts.exec(
          "area-datetime",
          "zoomX",
          new Date("01 Jan 2013").getTime(),
          new Date("27 Feb 2013").getTime()
        );
        break;
      case "all":
        ApexCharts.exec(
          "area-datetime",
          "zoomX",
          new Date("23 Jan 2012").getTime(),
          new Date("27 Feb 2013").getTime()
        );
        break;
      default:
    }
  };

  // sdgsadgdahdgdsafsa
  const lineData = [
    {
      name: "Gross Generation",
      data: [14.45, 12.25, 13, 11.1],
    },
    {
      name: "PLF",
      data: [70, 50, 30, 20],
    },
    {
      name: "APC",
      data: [8, 9, 7, 8],
    },
    {
      name: "CoalStock",
      data: [64753, 65780, 47947, 61129],
    },
    {
      name: "Coal Receipt",
      data: [59536, 59726, 51460, 93265],
    },
    {
      name: "Coal Consumption",
      data: [61767, 62917, 69206, 15841],
    },
  ];
  const lineOption = {
    chart: {
      height: 350,
      type: "line",
      dropShadow: {
        enabled: true,
        color: "#fff",
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2,
      },
      toolbar: {
        show: false,
      },
    },
    // colors: ["#77B6EA", "#545454", "#000", "#FFFF00", "#0000FF", "#FF0000"],
    colors: ["#77B6EA", "#545454", "#000"],
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: "smooth",
    },
    title: {
      text: "Chart",
      align: "left",
    },
    grid: {
      borderColor: "#e7e7e7",
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    markers: {
      size: 1,
    },
    xaxis: {
      // categories: ["Mundra", "Tiroda", "Kawai", "Raipur"],
      categories: thirdPlantName,
      title: {
        text: "Plants",
      },
    },
    yaxis: {
      title: {
        text: "Values",
      },
      min: 0,
      max: 2003265,
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
      floating: true,
      offsetY: -25,
      offsetX: -5,
    },
  };
  const coalstock = [
    {
      name:
        KPIName == "Coal Stock"
          ? "CoalStock"
          : KPIName == "Coal Receipt"
          ? "CoalReceipt"
          : KPIName == "Coal Consumption"
          ? "CoalConsumption"
          : "",
      data: coalStockData,
    },
    {
      name: "CoalReceipt",
      data: coalReceiptData,
    },
    {
      name: "CoalConsumption",
      data: coalConsuptionData,
    },
  ];

  const options = {
    chart: {
      id: "area-datetime",
      type: "area",
      height: 350,
      zoom: {
        autoScaleYaxis: true,
      },
    },
    annotations: {
      yaxis: [
        {
          y: 30,
          borderColor: "#999",
          label: {
            show: true,
            text: "Support",
            style: {
              color: "#fff",
              background: "#00E396",
            },
          },
        },
      ],
      xaxis: [
        {
          x: new Date("1 Jan 2024").getTime(),
          borderColor: "#999",
          yAxisIndex: 0,
          label: {
            show: true,
            text: "Rally",
            style: {
              color: "#fff",
              background: "#775DD0",
            },
          },
        },
      ],
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 0,
      style: "hollow",
    },
    xaxis: {
      type: "datetime",
      min: new Date("01 Jan 2024").getTime(),
      tickAmount: 6,
    },
    tooltip: {
      x: {
        format: "dd MMM yyyy",
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 100],
      },
    },
  };
  useEffect(() => {
    let data = "";
    if (KPIName == "Coal Stock") {
      data = KPIName;
    } else if (KPIName == "Coal Receipt") {
      data = KPIName;
    } else if (KPIName == "Coal Consumption") {
      data = KPIName;
    }
    getReq(
      `/v1/plant/data?plantName=${plantName}&${KPIName}=kpi&CoalStock=${data}&CoalReceipt=${data}&CoalConsumption=${data}`
    ).then((res) => {
      if (res.status) {
        setTableData(res.data.data);
        let data = res.data.data;

        // if (KPIName == "Coal Stock") {
        let dd = [];
        data.map((item) => {
          return item.plantdata?.map((value) => {
            dd.push([
              moment.utc(item.created_at).format("MM/DD/YYYY"),
              Number(value.value),
            ]);
          });
          // dd.push([
          //   moment.utc(item.created_at).format("MM/DD/YYYY"),
          //   Number(item.value),
          // ]);
        });
        setCoalStockData(dd);
        // const plf = data.plantdata?.reduce((accum, current) => {
        //   return accum + Number(current.value);
        // }, 0);
        // let obj = {
        //   name: "PLF",
        //   PLF: plf,
        // };
        // setAllChartData(obj);
        // }
      } else {
        console.log("error");
      }
    });
  }, [plantName, KPIName]);

  // useEffect(() => {
  //   if (tableData?.length) {
  //     if (KPIName == "PLF") {
  //       console.log(tableData, "dd");
  //       const plf = tableData.reduce((accum, current) => {
  //         return accum + Number(current.value);
  //       }, 0);

  //       let obj = {
  //         name: "PLF",
  //         PLF: plf,
  //       };
  //       setAllChartData(obj);
  //     }
  //   }
  // }, [tableData]);

  // console.log(allChartData, "allchart");
  return (
    <>
      <Loader isLoading={isLoading} />
      <div>
        <div className="upper flex flex-col gap-4">
          <h6 className="flex items-center gap-2">
            Dashboard <FiAward className="text-[22px] text-themecyan-darker" />{" "}
          </h6>
          {/* <div className="flex justify-between">
          <button className="btn-outline-primary w-fit">
            View Public Profile
          </button>
          <button className="btn btn-primary h-[48px] w-fit">
            Start Notarizing
          </button>
        </div> */}

          {/* <NavLink
          to="#"
          className="text-[14px] font-medium text-themecyan-darker underline"
        >
          Show F&F Discount Code
        </NavLink> */}
        </div>
        <div className="stats border-l-themecyan-darker grid grid-cols-4 gap-8 mt-10 p-3 rounded-2xl bg-themecyan-lighteralt">
          <div
            onClick={() => navigate("/plant-list")}
            className="p-4 cursor-pointer  border-2 bg-white border-grey-30 border-l-[6px] border-l-blue-dark rounded-lg flex items-center justify-between"
          >
            <div className="space-y-1 ">
              <h6 className="text-themecyan-text2 mb-2 text-[16px]">
                Total Plants
              </h6>
              <div className="flex gap-2 ">
                {/* <span className="font-bold min-w-[80px] ">Previous</span>{" "} */}
                <span className="font-bold">{chartData?.length || 5}</span>{" "}
              </div>
              {/* <div className="flex gap-2">
              <span className="font-bold min-w-[80px] ">Current</span>{" "}
              <span className="font-bold">$8.00</span>{" "}
            </div> */}
            </div>
            <div>
              <GiThrownCharcoal className="text-[32px]" />
            </div>
          </div>
          <div className="p-4  border-2 bg-white border-grey-30 border-l-[6px] border-l-themecyan-darker rounded-lg flex items-center justify-between gap-2">
            <div className="space-y-1 flex-1">
              <h6 className="text-themecyan-text2 mb-2 text-[16px]">
                Coal Stock
              </h6>
              <div className="flex ">
                <span className="font-bold">4</span>{" "}
              </div>
            </div>
            <div>
              <BsBasket3Fill className="text-[32px]" />
            </div>
          </div>
          <div className="p-4  border-2 bg-white border-grey-30 border-l-[6px] border-l-yellow-dark rounded-lg flex items-center justify-between gap-2">
            <div className="space-y-1 flex-1">
              <h6 className="text-themecyan-text2 mb-2 text-[16px]">
                Coal Receipt
              </h6>
              <div className="flex ">
                <span className="font-bold">4</span>{" "}
              </div>
            </div>
            <div>
              <FaReceipt className="text-[32px]" />
            </div>
          </div>
          <div className="p-4   border-2 bg-white border-grey-30 border-l-[6px] border-l-green-dark rounded-lg flex items-center justify-between gap-2">
            <div className="space-y-1 flex-1">
              <h6 className="text-themecyan-text2 mb-2 text-[16px]">
                Coal Consumption
              </h6>
              <div className="flex ">
                <span className="font-bold">4</span>{" "}
              </div>
            </div>
            <div>
              <FaFireBurner className="text-[32px]" />
            </div>
          </div>
        </div>

        {/* tables */}
        <div className="w-full mt-10 data-grid">
          <div className="mt-5" id="chart">
            <ReactApexChart
              options={lineOption}
              series={thirdData}
              type="line"
              height={350}
            />
          </div>
          <div className="w-full  flex mt-10 data-grid">
            <LineBarAreaChart newDataPr={chartData} />
          </div>

          {/* <div className="mt-7">
            <Chart
              chartType="Bar"
              width="100%"
              height="400px"
              data={data}
              options={options1}
            />
          </div> */}
          {/* <div className="mt-5" id="chart">
            <ReactApexChart
              options={lineOption}
              series={thirdData}
              type="line"
              height={350}
            />
          </div> */}
          {/* <div className=" h-[600px] w-full">
          <DataGrid
            rows={rows}
            getEstimatedRowHeight={() => 52}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 8,
                },
              },
            }}
            pageSizeOptions={[8, 10, 15]}
            slots={{
              toolbar: GridToolbar,
              loadingOverlay: LinearProgress,
            }}
            // hideFooter
            // hideFooterPagination
            disableColumnMenu
          />
        </div> */}
          <ul class="nav nav-tabs">
            {newData1?.map((item, index) => {
              return (
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li
                    className="nav-item"
                    role="presentation"
                    onClick={() => setKpiList(true)}
                  >
                    <button
                      className={
                        acivePlant == index ? `nav-link active` : "nav-link"
                      }
                      id="home-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#home"
                      type="button"
                      role="tab"
                      aria-controls="home"
                      aria-selected="true"
                      onClick={() => {
                        setActivePlant(index);
                        setPlantName(item);
                      }}
                    >
                      {item}
                    </button>
                  </li>
                </ul>
              );
            })}
          </ul>
          {kpiList ? (
            <div className="mt-5">
              <ul class="nav nav-tabs">
                {kpi?.map((item, index) => {
                  return (
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                      <li
                        className="nav-item"
                        role="presentation"
                        onClick={() => setPlantData(true)}
                      >
                        <button
                          className={
                            activeKPI == index ? `nav-link active` : "nav-link"
                          }
                          id="home-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#home"
                          type="button"
                          role="tab"
                          aria-controls="home"
                          aria-selected="true"
                          onClick={() => {
                            setActiveKPI(index);
                            setKPIName(item);
                          }}
                        >
                          {item}
                        </button>
                      </li>
                    </ul>
                  );
                })}
              </ul>
            </div>
          ) : (
            ""
          )}
          <div className="mt-5">
            {plantData && (
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li
                  className="nav-item"
                  role="presentation"
                  onClick={() => setActiveTabName("trend")}
                >
                  <button
                    className={
                      activeTabName == "trend" ? `nav-link active` : "nav-link"
                    }
                    id="home-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#home"
                    type="button"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                  >
                    Trend
                  </button>
                </li>
                <li
                  className="nav-item"
                  role="presentation"
                  onClick={() => setActiveTabName("table")}
                >
                  <button
                    className={
                      activeTabName == "table" ? `nav-link active` : "nav-link"
                    }
                    id="profile-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#profile"
                    type="button"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                  >
                    Table
                  </button>
                </li>
                <li
                  className="nav-item"
                  role="presentation"
                  onClick={() => setActiveTabName("chart")}
                >
                  <button
                    className={
                      activeTabName == "chart" ? `nav-link active` : "nav-link"
                    }
                    id="contact-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#contact"
                    type="button"
                    role="tab"
                    aria-controls="contact"
                    aria-selected="false"
                  >
                    Chart
                  </button>
                </li>
              </ul>
            )}
            {activeTabName == "chart" ? (
              <div className="mt-5">
                <div className="w-full  flex mt-10 ">
                  {/* <ComposedChart
                    width={800}
                    height={400}
                    data={allChartData}
                    margin={{
                      top: 20,
                      right: 20,
                      bottom: 20,
                      left: 20,
                    }}
                  >
                    <CartesianGrid stroke="#f5f5f5" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />

                    <Bar
                      type="monotone"
                      dataKey="PLF"
                      fill="#8884d8"
                      stroke="#8884d8"
                    />

                    <Bar dataKey="coalReceipt" barSize={20} fill="#413ea0" />
                    <Bar type="monotone" dataKey="coalStock" stroke="#ff7300" />
                  </ComposedChart> */}
                </div>
              </div>
            ) : activeTabName == "table" ? (
              <div className="container mt-5">
                <div className="card">
                  <div className="card-body">
                    {/* <h6 className="font-bold">Coal Stock Detail</h6> */}
                    <div className="table-responsive">
                      {KPIName == "APC" ? (
                        <table className="table table-bordered table-striped">
                          <thead>
                            <tr>
                              <th scope="col">Sr. No.</th>
                              <th scope="col">APC</th>
                              <th scope="col">Created At</th>
                            </tr>
                          </thead>
                          <tbody>
                            {tableData?.length ? (
                              tableData?.map((item, index) => {
                                return (
                                  <tr key={index}>
                                    <td scope="row">{index + 1}</td>
                                    <td>{item.apc || "--"}</td>
                                    <td>
                                      {moment(item.created_at).format(
                                        "DD-MM-YYYY"
                                      ) || "--"}
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <span>Data not found</span>
                            )}
                          </tbody>
                        </table>
                      ) : KPIName == "PLF" ? (
                        <table className="table table-bordered table-striped">
                          <thead>
                            <tr>
                              <th scope="col">Sr. No.</th>
                              <th scope="col">PLF</th>
                              <th scope="col">Created At</th>
                            </tr>
                          </thead>
                          <tbody>
                            {tableData?.length ? (
                              tableData?.map((item, index) => {
                                return (
                                  <tr key={index}>
                                    <td scope="row">{index + 1}</td>
                                    <td>{item.ple || "--"}</td>
                                    <td>
                                      {moment(item.created_at).format(
                                        "DD-MM-YYYY"
                                      ) || "--"}
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <span>Data not found</span>
                            )}
                          </tbody>
                        </table>
                      ) : KPIName == "GrossGeneration" ? (
                        <table className="table table-bordered table-striped">
                          <thead>
                            <tr>
                              <th scope="col">Sr. No.</th>
                              <th scope="col">Gross Generation</th>
                              <th scope="col">Created At</th>
                            </tr>
                          </thead>
                          <tbody>
                            {tableData?.length ? (
                              tableData?.map((item, index) => {
                                return (
                                  <tr key={index}>
                                    <td scope="row">{index + 1}</td>
                                    <td>{item.gross_generation || "--"}</td>
                                    <td>
                                      {moment(item.created_at).format(
                                        "DD-MM-YYYY"
                                      ) || "--"}
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <span>Data not found</span>
                            )}
                          </tbody>
                        </table>
                      ) : KPIName == "Coal Stock" ||
                        KPIName == "Coal Receipt" ||
                        KPIName == "Coal Consumption" ? (
                        <table className="table table-bordered table-striped">
                          <thead>
                            <tr>
                              <th scope="col">Sr. No.</th>
                              <th scope="col">Type</th>
                              <th scope="col">MT</th>
                              <th scope="col">Value</th>
                              <th scope="col">Created At</th>
                              {/* <th scope="col">Total</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {tableData?.length ? (
                              tableData?.map((item, index) => {
                                return item?.plantdata?.map((value, i) => {
                                  return (
                                    <tr key={i}>
                                      <td scope="row">{i + 1}</td>
                                      <td>{value.type || "--"}</td>
                                      <td>{value.umo || "--"}</td>
                                      <td>{value.value || "--"}</td>
                                      <td>
                                        {moment(item.created_at).format(
                                          "DD-MM-YYYY"
                                        ) || "--"}
                                      </td>
                                    </tr>
                                  );
                                });
                              })
                            ) : (
                              <span>Data not found</span>
                            )}
                          </tbody>
                        </table>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : activeTabName == "trend" ? (
              KPIName == "Coal Stock" ||
              KPIName == "Coal Receipt" ||
              KPIName == "Coal Consumption" ? (
                <div id="chart" className="mt-5">
                  <div className="toolbar gap-5">
                    <button
                      id="one_month"
                      onClick={() => updateData("one_month")}
                      className={
                        selection === "one_month"
                          ? "active btn btn-primary"
                          : ""
                      }
                    >
                      1 MONTH
                    </button>
                    {/* &nbsp;
                  <button
                    id="six_months"
                    onClick={() => updateData("six_months")}
                    className={selection === "six_months" ? "active" : ""}
                  >
                    6M
                  </button> */}
                    &nbsp;
                    <button
                      id="one_year"
                      onClick={() => updateData("one_year")}
                      className={
                        selection === "one_year" ? "active btn btn-primary" : ""
                      }
                    >
                      1 YEAR
                    </button>
                    {/* &nbsp;
                  <button
                    id="ytd"
                    onClick={() => updateData("ytd")}
                    className={selection === "ytd" ? "active" : ""}
                  >
                    YTD
                  </button>
                  &nbsp;
                  <button
                    id="all"
                    onClick={() => updateData("all")}
                    className={selection === "all" ? "active" : ""}
                  >
                    ALL
                  </button> */}
                  </div>

                  <div id="chart-timeline">
                    <ReactApexChart
                      options={options}
                      series={coalstock}
                      type="line"
                      height={350}
                    />
                  </div>
                </div>
              ) : (
                ""
                // <div className="mt-5 text-center">No data available</div>
              )
            ) : (
              ""
            )}
            {/* <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="home-tab"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
                <h6 className=" font-bold mb-2 text-[20px] mt-5">
                  Plant Detail
                </h6>
                <div className=" mt-3 w-[700px] text-center">
                  <div className="card">
                    <div className="card-body">
                      <div className="mt-2 px-3 w-full">
                        <div className="space-y-1  justify-center items-center">
                          <div className="flex gap-5 justify-between ">
                            <span className="text-themecyan-text2 font-bold ">
                              Plant Name
                            </span>{" "}
                            <span className="text-themecyan-text2 font-bold">
                              {data[0]?.plantname}
                            </span>{" "}
                          </div>
                          <div className="flex gap-5 justify-between">
                            <span className=" text-themecyan-text2 font-bold ">
                              Location
                            </span>{" "}
                            <span className="text-themecyan-text2 font-bold">
                              {data[0]?.location}
                            </span>{" "}
                          </div>
                          <div className="flex gap-5 justify-between">
                            <span className="text-themecyan-text2 font-bold ">
                              PLF
                            </span>{" "}
                            <span className="text-themecyan-text2 font-bold">
                              {data[0]?.plf || "--"}
                            </span>{" "}
                          </div>
                          <div className="flex gap-5 justify-between">
                            <span className="text-themecyan-text2 font-bold ">
                              APC
                            </span>{" "}
                            <span className="text-themecyan-text2 font-bold">
                              {data[0]?.apc || "--"}
                            </span>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="profile-tab"
                role="tabpanel"
                aria-labelledby="profile-tab"
              >
                <div className="container mt-5">
                  <div className="card">
                    <div className="card-body">
                      <h6 className="font-bold">Coal Consumption Detail</h6>
                      <div className="table-responsive">
                        <table className="table table-bordered table-striped">
                          <thead>
                            <tr>
                              <th scope="col">Id</th>
                              <th scope="col">First</th>
                              <th scope="col">Last</th>
                              <th scope="col">Handle</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td scope="row">1</td>
                              <td>Mark</td>
                              <td>Otto</td>
                              <td>@mdo</td>
                            </tr>
                            <tr>
                              <td scope="row">2</td>
                              <td>Jacob</td>
                              <td>Thornton</td>
                              <td>@fat</td>
                            </tr>
                            <tr>
                              <td scope="row">3</td>
                              <td>Larry the Bird</td>
                              <td>Larry </td>
                              <td>@twitter</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="container mt-5">
                  <div className="card">
                    <div className="card-body">
                      <h6 className="font-bold">Coal Consumption Detail</h6>
                      <div className="table-responsive">
                        <table className="table table-bordered table-striped">
                          <thead>
                            <tr>
                              <th scope="col">Id</th>
                              <th scope="col">First</th>
                              <th scope="col">Last</th>
                              <th scope="col">Handle</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td scope="row">1</td>
                              <td>Mark</td>
                              <td>Otto</td>
                              <td>@mdo</td>
                            </tr>
                            <tr>
                              <td scope="row">2</td>
                              <td>Jacob</td>
                              <td>Thornton</td>
                              <td>@fat</td>
                            </tr>
                            <tr>
                              <td scope="row">3</td>
                              <td>Larry the Bird</td>
                              <td>Larry </td>
                              <td>@twitter</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="contact"
                role="tabpanel"
                aria-labelledby="contact-tab"
              >
                ...Hi
              </div>
            </div> */}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default DashboardLanding;
