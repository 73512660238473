import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { sideBarLinks } from "../utils/constants";
import { reactIcons } from "./../utils/icons";
import logo from "../assets/images/Adani_logo.png";

const Sidebar = ({ setIsOpen }) => {
  const navigate = useNavigate();

  return (
    <div className="h-screen flex flex-col justify-between bg-black shadow-lg shadow-themecyan-lighter  overflow-auto w-[250px] py-2     ">
      <div className="flex items-center gap-2 justify-between pr-1 py-0">
        <h4
          className="cursor-pointer px-3 mt-[-30px] w-[200px]"
          onClick={() => navigate("/dashboard")}
        >
          <img src={logo} alt="logo" />
        </h4>
        {/* <button
          onClick={() => setIsOpen(true)}
          className="w-10 h-10 mycenter bg-themecyan-lighter rounded-full text-3xl"
        >
          {reactIcons.arrowleft}
        </button> */}
      </div>

      <div className="flex flex-col  mt-0.2 xxl:mt-12 flex-1">
        {sideBarLinks.map((item, index) => (
          <NavLink
            style={{ textDecoration: "none" }}
            key={index}
            to={item.path}
            className={({ isActive }) =>
              `relative font-semibold  py-3 w-full px-4  flex gap-4 items-center cursor-pointer duration-300 hover:bg-themecyan-darker hover:text-white ${
                isActive ? "bg-themecyan-darker text-white" : "text-grey-90"
              }`
            }
          >
            <span className="text-xl">{item.icon}</span>{" "}
            <span className="text-[15px] xxl:text-[15px]"> {item.title} </span>{" "}
          </NavLink>
        ))}
      </div>
      <div>
        <NavLink
          style={{ textDecoration: "none" }}
          to={"/"}
          className={({ isActive }) =>
            `relative font-semibold text-white  py-4 w-full px-4  flex gap-4 items-center cursor-pointer duration-300   `
          }
        >
          <span className="text-2xl">{reactIcons.logout}</span>
          <span className="text-[18px] xxl:text-[22px]"> Logout </span>
        </NavLink>
      </div>
    </div>
  );
};

export default Sidebar;
