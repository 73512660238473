import { Routes, Route, useLocation } from "react-router-dom";
import Credentials from "./components/Credentials";
import DashboardLanding from "./components/DashboardLanding";
import Earnings from "./components/Earnings";
import Profile from "./components/Profile";
import Schedule from "./components/Schedule";
import Sessions from "./components/Sessions";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import "animate.css";
import EditSignature from "./components/EditSignature";
import { createContext, useEffect } from "react";
import PlantDetails from "./pages/PlantDetails";

function App() {
  // const { pathname } = useLocation();
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, [pathname]);

  return (
    <Routes>
      <Route exact path="/" element={<Login />} />
      <Route path="/" element={<Dashboard />}>
        <Route index path="dashboard" element={<DashboardLanding />}></Route>
        <Route path="plant-list" element={<Schedule />}></Route>
        <Route path="profile" element={<Profile />}></Route>
        <Route path="plant-details/:id" element={<PlantDetails />}></Route>

        {/* <Route path="schedule" element={<Schedule />}></Route>
        <Route path="credentials" element={<Credentials />}></Route>
        <Route path="sessions" element={<Sessions />}></Route>
        <Route path="earnings" element={<Earnings />}></Route>
        <Route path="edit-signature" element={<EditSignature />}></Route> */}
      </Route>
    </Routes>
  );
}

export default App;
