import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getReq } from "../utils/ApiHandler";
import { Footer } from "../components/Footer";
import Loader from "../components/Loader";

const PlantDetails = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [totalNewData, setTotalNewData] = useState([]);
  const plantA = {
    id: 1,
    plant_name: "Plant A",
    location: "Madhya Pradesh",
    mobile_number: "8754821456",
    plf: "8%",
    apc: "10%",
  };
  const plantB = {
    id: 2,
    plant_name: "Plant B",
    location: "Gujarat",
    mobile_number: "8754821456",
    plf: "50%",
    apc: "70%",
  };
  const [coalStock1, setCoalStock1] = useState([]);
  const [receiptStock1, setReceiptStock1] = useState([]);
  const [consuptionStock1, setConsuptionStock1] = useState([]);
  const [stockTotal, setStockTotal] = useState();
  const [receiptTotal, setReceiptTotal] = useState();
  const [consuptionTotal, setConsuptionTotal] = useState();
  const [plantSummary, setPlantSummary] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // useEffect(() => {
  //   if (id == 1) {
  //     setData(plantA);
  //   } else {
  //     setData(plantB);
  //   }
  // }, [id]);

  const getData = async () => {
    setIsLoading(true);
    getReq("/v1/data").then((res) => {
      if (res.status) {
        setData(res.data.data);
        setIsLoading(false);
      } else {
        console.log("error");
        setIsLoading(false);
      }
    });
  };

  const getTotalCount = () => {
    setIsLoading(true);

    getReq("/v1/count").then((res) => {
      if (res.status) {
        setTotalNewData(res.data.data);
        setIsLoading(false);
      } else {
        console.log("error");
        setIsLoading(false);
      }
    });
  };

  useEffect(() => {
    getData();
    // getTotalCount();
  }, []);

  useEffect(() => {
    if (data?.length) {
      let summary = data?.filter((summ) => summ.id == id);
      setPlantSummary(summary);

      summary?.map((item) => {
        let coalStock = item.plantdata.filter(
          (value) => value.type === "Coal Stock" && item.id === value.plantId
        );

        setCoalStock1(coalStock);
        const coalStockTotal = coalStock.reduce((accum, current) => {
          return accum + Number(current.value);
        }, 0);
        setStockTotal(coalStockTotal);

        const coalReceipt = item.plantdata.filter(
          (value) => value.type == "Coal Receipt" && item.id === value.plantId
        );

        setReceiptStock1(coalReceipt);
        const coalReceiptTotal = coalReceipt.reduce((accum, current) => {
          return accum + Number(current.value);
        }, 0);
        setReceiptTotal(coalReceiptTotal);
        const coalConsuption = item.plantdata.filter(
          (value) =>
            value.type == "Coal Consumption" && item.id === value.plantId
        );
        setConsuptionStock1(coalConsuption);
        const coalConsuptionTotal = coalConsuption.reduce((accum, current) => {
          return accum + Number(current.value);
        }, 0);
        setConsuptionTotal(coalConsuptionTotal);
      });

      // setNewData(updatedData);
    }
  }, [data]);

  return (
    <>
      <Loader isLoading={isLoading} />
      {/* <ul className="nav nav-tabs" id="myTab" role="tablist">
        <li className="nav-item" role="presentation">
          <button
            className="nav-link active"
            id="home-tab"
            data-bs-toggle="tab"
            data-bs-target="#home"
            type="button"
            role="tab"
            aria-controls="home"
            aria-selected="true"
          >
            Plants
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className="nav-link"
            id="profile-tab"
            data-bs-toggle="tab"
            data-bs-target="#profile"
            type="button"
            role="tab"
            aria-controls="profile"
            aria-selected="false"
          >
            Plant Summary
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className="nav-link"
            id="contact-tab"
            data-bs-toggle="tab"
            data-bs-target="#contact"
            type="button"
            role="tab"
            aria-controls="contact"
            aria-selected="false"
          >
            Contact
          </button>
        </li>
      </ul> */}
      {/* <div className="tab-content" id="myTabContent">
        <div
          className="tab-pane fade show active"
          id="home"
          role="tabpanel"
          aria-labelledby="home-tab"
        >
          <h6 className=" font-bold mb-2 text-[20px] mt-5">Plant Detail</h6>
          <div className=" mt-3 w-[700px] text-center">
            <div className="card">
              <div className="card-body">
                <div className="mt-2 px-3 w-full">
                  <div className="space-y-1  justify-center items-center">
                    <div className="flex gap-5 justify-between ">
                      <span className="text-themecyan-text2 font-bold ">
                        Plant Name
                      </span>{" "}
                      <span className="text-themecyan-text2 font-bold">
                        {data[0]?.plantname}
                      </span>{" "}
                    </div>
                    <div className="flex gap-5 justify-between">
                      <span className=" text-themecyan-text2 font-bold ">
                        Location
                      </span>{" "}
                      <span className="text-themecyan-text2 font-bold">
                        {data[0]?.location}
                      </span>{" "}
                    </div>
                    <div className="flex gap-5 justify-between">
                      <span className="text-themecyan-text2 font-bold ">
                        PLF
                      </span>{" "}
                      <span className="text-themecyan-text2 font-bold">
                        {data[0]?.plf || "--"}
                      </span>{" "}
                    </div>
                    <div className="flex gap-5 justify-between">
                      <span className="text-themecyan-text2 font-bold ">
                        APC
                      </span>{" "}
                      <span className="text-themecyan-text2 font-bold">
                        {data[0]?.apc || "--"}
                      </span>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>
        <div
          className="tab-pane fade"
          id="profile"
          role="tabpanel"
          aria-labelledby="profile-tab"
        >
          <div className="container mt-5">
            <div className="card">
              <div className="card-body">
                <h6 className="font-bold">Coal Consumption Detail</h6>
                <div className="table-responsive">
                  <table className="table table-bordered table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Id</th>
                        <th scope="col">First</th>
                        <th scope="col">Last</th>
                        <th scope="col">Handle</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td scope="row">1</td>
                        <td>Mark</td>
                        <td>Otto</td>
                        <td>@mdo</td>
                      </tr>
                      <tr>
                        <td scope="row">2</td>
                        <td>Jacob</td>
                        <td>Thornton</td>
                        <td>@fat</td>
                      </tr>
                      <tr>
                        <td scope="row">3</td>
                        <td>Larry the Bird</td>
                        <td>Larry </td>
                        <td>@twitter</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className="container mt-5">
            <div className="card">
              <div className="card-body">
                <h6 className="font-bold">Coal Consumption Detail</h6>
                <div className="table-responsive">
                  <table className="table table-bordered table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Id</th>
                        <th scope="col">First</th>
                        <th scope="col">Last</th>
                        <th scope="col">Handle</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td scope="row">1</td>
                        <td>Mark</td>
                        <td>Otto</td>
                        <td>@mdo</td>
                      </tr>
                      <tr>
                        <td scope="row">2</td>
                        <td>Jacob</td>
                        <td>Thornton</td>
                        <td>@fat</td>
                      </tr>
                      <tr>
                        <td scope="row">3</td>
                        <td>Larry the Bird</td>
                        <td>Larry </td>
                        <td>@twitter</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="tab-pane fade"
          id="contact"
          role="tabpanel"
          aria-labelledby="contact-tab"
        >
          ...Hi
        </div>
      </div> */}

      <div className=" mt-3 w-full text-center">
        <div className="card">
          <h5 className="mt-2 font-bold">Plant Summary</h5>
          <div className="card-body w-[500px] ml-[350px]">
            <div className="mt-2 px-3 w-full">
              <div className="space-y-1  justify-center items-center">
                <div className="flex gap-5 justify-between ">
                  <span className="text-themecyan-text2 font-bold ">
                    Plant Name
                  </span>{" "}
                  <span className="text-themecyan-text2 font-bold">
                    {plantSummary[0]?.plantname}
                  </span>{" "}
                </div>
                <div className="flex gap-5 justify-between ">
                  <span className=" text-themecyan-text2 font-bold ">
                    Location
                  </span>{" "}
                  <span className="text-themecyan-text2 font-bold">
                    {plantSummary[0]?.location}
                  </span>{" "}
                </div>
                <div className="flex gap-5 justify-between">
                  <span className="text-themecyan-text2 font-bold ">
                    Gross Generation
                  </span>{" "}
                  <span className="text-themecyan-text2 font-bold">
                    {(Math.random() * 100).toFixed(2)}
                  </span>{" "}
                </div>
                <div className="flex gap-5 justify-between">
                  <span className="text-themecyan-text2 font-bold ">PLF</span>{" "}
                  <span className="text-themecyan-text2 font-bold">
                    {(Math.random() * 100).toFixed(2)}
                  </span>{" "}
                </div>
                <div className="flex gap-5 justify-between">
                  <span className="text-themecyan-text2 font-bold ">APC</span>{" "}
                  <span className="text-themecyan-text2 font-bold">
                    {(Math.random() * 100).toFixed(2)}
                  </span>{" "}
                </div>
                <div className="flex gap-5 justify-between">
                  <span className="text-themecyan-text2 font-bold ">
                    Phone Number
                  </span>{" "}
                  <span className="text-themecyan-text2 font-bold">
                    {plantSummary[0]?.phonenumber || "--"}
                  </span>{" "}
                </div>
                {/* <div className="flex gap-5 ">
                  <span className="text-themecyan-text2 font-bold ">APC</span>{" "}
                  <span className="text-themecyan-text2 font-bold">
                    {data[0]?.apc || "--"}
                  </span>{" "}
                </div> */}
              </div>
            </div>
          </div>
          <div className="container mt-5">
            <div className="card">
              <div className="card-body">
                <h6 className="font-bold">Coal Stock Detail</h6>
                <div className="table-responsive">
                  <table className="table table-bordered table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Sr. No.</th>
                        <th scope="col">Type</th>
                        <th scope="col">MT</th>
                        <th scope="col">Value</th>
                        <th scope="col">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {coalStock1?.length ? (
                        coalStock1?.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td scope="row">{index + 1}</td>
                              <td>{item.parameter}</td>
                              <td>{item.umo}</td>
                              <td>{item.value}</td>
                              <td>{stockTotal}</td>
                            </tr>
                          );
                        })
                      ) : (
                        <span>Data not found</span>
                      )}
                      {/* <tr>
                        <td scope="row">2</td>
                        <td>Jacob</td>
                        <td>Thornton</td>
                        <td>@fat</td>
                      </tr>
                      <tr>
                        <td scope="row">3</td>
                        <td>Larry the Bird</td>
                        <td>Larry </td>
                        <td>@twitter</td>
                      </tr> */}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="container mt-5">
            <div className="card">
              <div className="card-body">
                <h6 className="font-bold">Coal Receipt Detail</h6>
                <div className="table-responsive">
                  <table className="table table-bordered table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Sr. No.</th>
                        <th scope="col">Type</th>
                        <th scope="col">MT</th>
                        <th scope="col">Value</th>
                        <th scope="col">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {receiptStock1?.length ? (
                        receiptStock1?.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td scope="row">{index + 1}</td>
                              <td>{item.parameter}</td>
                              <td>{item.umo}</td>
                              <td>{item.value}</td>
                              <td>{receiptTotal}</td>
                            </tr>
                          );
                        })
                      ) : (
                        <span>Data not found</span>
                      )}
                      {/* <tr>
                        <td scope="row">2</td>
                        <td>Jacob</td>
                        <td>Thornton</td>
                        <td>@fat</td>
                      </tr>
                      <tr>
                        <td scope="row">3</td>
                        <td>Larry the Bird</td>
                        <td>Larry </td>
                        <td>@twitter</td>
                      </tr> */}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="container mt-5">
            <div className="card">
              <div className="card-body">
                <h6 className="font-bold">Coal Consumption Detail</h6>
                <div className="table-responsive">
                  <table className="table table-bordered table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Sr. No.</th>
                        <th scope="col">Type</th>
                        <th scope="col">MT</th>
                        <th scope="col">Value</th>
                        <th scope="col">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {consuptionStock1?.length ? (
                        consuptionStock1?.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td scope="row">{index + 1}</td>
                              <td>{item.parameter}</td>
                              <td>{item.umo}</td>
                              <td>{item.value}</td>
                              <td>{consuptionTotal}</td>
                            </tr>
                          );
                        })
                      ) : (
                        <span>Data not found</span>
                      )}
                      {/* <tr>
                        <td scope="row">2</td>
                        <td>Jacob</td>
                        <td>Thornton</td>
                        <td>@fat</td>
                      </tr>
                      <tr>
                        <td scope="row">3</td>
                        <td>Larry the Bird</td>
                        <td>Larry </td>
                        <td>@twitter</td>
                      </tr> */}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
        {/* </div> */}
      </div>
    </>
  );
};

export default PlantDetails;
