import Backdrop from "@material-ui/core/Backdrop";
import { CircularProgress } from "@mui/material";

const Loader = ({ isLoading }) => (
  <Backdrop
    sx={{ color: "#000", zIndex: 99999 }}
    open={isLoading ? isLoading : false}
  >
    <CircularProgress sx={{ color: "#000" }} />
  </Backdrop>
);

export default Loader;
